<template>
  <div
    :class="{ 'is-loaded':loaded }"
    class="loading-screen v-center">
    <div class="loading-screen-text">
      <span class="text-yellow text-bold">Tysabri</span><br />
      <span class="text-green text-bold">Patient</span><br />
      <span class="text-blue">Alert<br />Card</span>
    </div>
    <div class="loading-screen-footer">
      <img class="loading-screen-logo" src="../assets/tysabri_logo.png" alt="" />
      <p>
        Biogen-186460<br />
        Date of preparation: January 2023
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',
  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    load() {
      setTimeout(() => {
        this.loaded = true;
      }, 1500);
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style lang="scss">
.loading-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: #FFF;
  transition: opacity 1.5s ease-in-out;
  opacity: 1;
}
.is-loaded {
  opacity: 0;
  pointer-events: none;
}
.loading-screen-text {
  padding-top: 240px;
  text-transform: uppercase;
  font-size: 60px;
  width: 270px;
  margin: 0 auto;
  line-height: 1;
}
.loading-screen-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  pointer-events: none;
}
.loading-screen-logo {
  display: inline-block;
  margin-bottom: 10px;
  max-width: 140px;
}
@media all and (min-width: 40em) {
  .loading-screen-text {
    font-size: 80px;
    width: 360px;
    padding-top: 110px;
  }
  .loading-screen-logo {
    max-width: 200px;
    margin-left: 280px;
  }
}
</style>

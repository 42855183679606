<template>
  <div class="grid-x bg-white">
    <div class="cell small-12 medium-6 medium-offset-3">
      <div class="yellow-box">
        <p class="text-bold">
          Reporting of side effects
        </p>
        <p>
          If you get any side effects, talk to your doctor. This includes any possible side effects
          not listed in the patient leaflet. You can also report side effects directly via the
          national reporting system.
        </p>
        <p class="text-bold">
          United Kingdom
        </p>
        <p>
          Yellow Card Scheme
          Website: https://yellowcard.mhra.gov.uk/ or search for MHRA Yellow Card in the Google Play
          or Apple App Store (UK).
        </p>
        <p class="text-bold">
          Ireland
        </p>
        <p>
          HPRA Pharmacovigilance, Earlsfort Terrace, Dublin, Ireland
          Tel: +353 1 6764971
          Fax: +353 1 6762517
          Website: www.hpra.ie
          Email: medsafety@hpra.ie
        </p>
        <p>
          By reporting side effects you can help provide more information on the safety of this
          medicine.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YellowBox',
};
</script>

<style lang="scss">
.yellow-box {
  background-color: #ffb300;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
}
</style>

<template>
  <div class="grid-x grid-padding-x main-header">
    <div class="cell small-12 medium-6 medium-offset-3">
      <span class="text-yellow text-bold text-upper">Tysabri</span>&nbsp;
      <span class="text-green text-bold text-upper">Patient</span>&nbsp;
      <span class="text-blue text-upper">Alert Card</span>
    </div>
    <div v-if="promoteInstall" class="cell small-12 medium-3">
      <span @click="install" class="text-blue text-upper install-link">
        Install
        <span class="install-tip">
          You can install this website to your device so it is available offline in case you need
          it when you have no internet connection.
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: [
    'promoteInstall',
  ],
  methods: {
    install() {
      this.$emit('installApp');
    },
  },
};
</script>

<style lang="scss">
  .main-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #FFF;
    padding: 10px 0;
  }
  .install-link {
    cursor: pointer;
    position: relative;
    &:hover {
      text-decoration: underline;
      .install-tip {
        opacity: 1;
      }
    }
  }
  .install-tip {
    display: inline-block;
    position: absolute;
    top: 101%;
    left: 0;
    padding: 3px;
    background-color: #F0F0F0;
    border-radius: 3px;
    text-transform: none;
    min-width: 200px;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }
</style>

<template>
  <div class="main-footer grid-x grid-padding-x">
    <div class="cell small-6 medium-3 medium-offset-3">
      <img class="footer-logo" src="../assets/biogen_logo.png" alt="" />
    </div>
    <div class="cell small-6 medium-3">
      <p class="text-small">
        <span class="text-bold">Marketing Authorisation Holder:</span>
        <br />
        Biogen Netherlands B.V.<br />
        Prins Mauritslaan 13<br />
        1171 LP Badhoevedorp<br />
        The Netherlands
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss">
.main-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #e6e6e7;
  padding-top: 10px;
}
@media all and (min-width: 40em) {
  .footer-logo {
    margin-top: 20px;
    max-width: 200px;
  }
}
</style>
